import React, { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface PaymobGuardProps {
  children?: ReactNode;
}

const PaymobGuard: FC<PaymobGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
};

PaymobGuard.propTypes = {
  children: PropTypes.node,
};

export default PaymobGuard;
