import { ReceiptOutlined } from '@material-ui/icons';
import { ReactNode } from 'react';
import { Home, Users, ShoppingCart, Globe, HelpCircle, Smartphone, Tool } from 'react-feather';

export interface MenuItem {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: MenuItem[];
  title: string;
}

export interface MenuSection {
  items: MenuItem[];
  subheader: string;
}

export const MenuItems: MenuSection[] = [
  {
    subheader: '',
    items: [
      {
        title: 'Dashboard',
        icon: Home,
        href: '/',
      },
    ],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Users',
        icon: Users,
        href: '/users',
      },
    ],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Stores',
        icon: ShoppingCart,
        href: '/stores',
      },
    ],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Translation',
        icon: Globe,
        href: '/translation',
      },
    ],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Support Issues',
        icon: HelpCircle,
        href: '/issues',
      },
    ],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Applications',
        icon: Smartphone,
        href: '/applications',
      },
    ],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Invoices',
        icon: ReceiptOutlined,
        href: '/invoices',
      },
    ],
  },
  {
    subheader: '',
    items: [
      {
        title: 'Maintenance',
        icon: Tool,
        href: '/maintenance',
      },
    ],
  },
];
