import firebase from 'src/lib/firebase';
import Business, { Steps } from 'src/types/Business';
// eslint-disable-next-line import/no-cycle
import { getStoreById } from './Stores';

const getBusinessInfoCollection = (): firebase.firestore.CollectionReference => firebase.firestore().collection('business-info');

const convertFromDocument = (snapshot: firebase.firestore.QueryDocumentSnapshot): Business => {
  const data = snapshot.data();
  return { ...data, id: snapshot.id } as Business;
};

const convertToFirestore = (businessInfo: Business): firebase.firestore.DocumentData => businessInfo;

export const businessInfoConverter = {
  fromFirestore: convertFromDocument,
  toFirestore: convertToFirestore,
};

export const getBusinessInfoByStoreId = async (storeId: string): Promise<Business> => {
  const collection = getBusinessInfoCollection();
  return (
    await collection
      .withConverter(businessInfoConverter)
      .doc(storeId)
      .get()
  ).data();
};

export const subscribeToBusinessForPaymob = (onNext: (businessInfo: Business[]) => void): (() => void) => {
  const collection = getBusinessInfoCollection();
  return collection
    .withConverter(businessInfoConverter)
    .where('step', '!=', Steps.contract)
    .onSnapshot(async snapshot => {
      const allPromises = snapshot.docs.map(async doc => {
        const store = await getStoreById(doc.id);
        return { ...doc.data(), store };
      });
      const payments = await Promise.all(allPromises);
      onNext(payments);
    });
};
