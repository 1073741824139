import Store from './store';

interface Business {
  id: string;
  registered: boolean;
  owners: Owner[];
  businessName?: string;
  commercialId?: CommercialID;
  taxId?: TaxID;
  vatCertificate?: VATCertificate;
  paymobUsername?: string;
  bankDetails?: BankDetails;
  step?: Steps;
  store?: Store;
  contractLink?: string;
  createdAt?: string;
  updatedAt?: string;
}

export enum Steps {
  contract = 'CONTRACT',
  bank = 'BANK',
  configuration = 'CONFIGURATION',
}

export type BankDetails = {
  bankAcc: string;
  bankName: string;
  branch: string;
  emailAddress: string;
  holderName: string;
  phoneNumber: string;
  swiftCode: string;
};

export type Owner = {
  id?: string;
  name: string;
  nationalID: string;
  nationalIDExpiryDate: string;
  attachments: string[];
};

export type CommercialID = {
  number: string;
  releaseDate: string;
  attachments: string[];
};

export type TaxID = {
  number: string;
  expiryDate: string;
  attachments: string[];
};

export type VATCertificate = {
  certificate: string;
  attachments: string[];
};

export default Business;
