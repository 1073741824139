export interface User {
  uid: string;
  customClaims?: { admin?: boolean, store?: string, paymob?: boolean, user?: boolean };
  disabled?: boolean;
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
  token: string;
  phone: string;
  emailVerified: boolean;
  phoneNumber: string;
  role?: ROLES;
  disableReason?: string;
  metadata?: Metadata;
  storeId?: string;
}

export interface Metadata {
  lastSignInTime?: string;
  creationTime?: string;
}

export interface Subscription {
  active: boolean;
  endDate?: string;
  startDate?: string;
}

export enum ROLES {
  USER = 'user',
  STORE = 'store',
  PAYMOB = 'paymob',
  ADMIN = 'admin',
}

export enum BusinessType {
  REGISTERED = 'REGISTERED',
  NON_REGISTERED = 'NON REGISTERED',
}

export enum AuthModes {
  RESET_PASSWORD = 'resetPassword',
  VERIFY_EMAIL = 'verifyEmail',
}

export enum EmailTypes {
  RESET_PASSWORD = 'RESET_PASSWORD',
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION'
}
