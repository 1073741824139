/* eslint-disable import/no-cycle */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from 'src/store';
import firebase from 'src/lib/firebase';

type AppDictionary = 'admin' | 'vendor' | 'storefront';

type AppTranslation = {
  [app in AppDictionary]?: AppTranslationMap;
};

interface AppTranslationMap {
  [key: string]: TranslationKey[];
}

interface TranslationKey {
  value: string;
}

interface TranslationState extends AppTranslation {
  loading: boolean;
  didFetch: string[];
}

interface AppTranslationPayload {
  app: string;
  languages: any[];
}

const initialState: TranslationState = {
  loading: true,
  didFetch: [],
};

const slice = createSlice({
  name: 'translations',
  initialState,
  reducers: {
    setTranslationsLoading(state: TranslationState, action: PayloadAction<TranslationState> | any) {
      return {...state, loading: action.payload};
    },
    setTranslations(state: TranslationState, action: PayloadAction<TranslationState> | any) {
      return {...state, ...action.payload};
    },
    addDidFetch(state: TranslationState, action: PayloadAction<{ key: string }> | any) {
      return {...state, didFetch: [...state.didFetch, action.payload.key]};
    },
  },
});

export const {reducer} = slice;
export const {setTranslationsLoading, setTranslations} = slice.actions;

export const getAppTranslations = (app: string): AppThunk => async (dispatch, getState) => {
  const {didFetch} = getState().translations;
  if (didFetch.includes(app)) return;
  dispatch(slice.actions.addDidFetch({key: app}));
  dispatch(slice.actions.setTranslationsLoading(true));
  const translationsCollection = firebase.firestore().collection('translations');
  const isTranslationExistForApp = (await translationsCollection.doc(app).get()).exists;
  if (isTranslationExistForApp) {
    translationsCollection
      .doc(app)
      .get()
      .then(async docSnapshot => {
        const {languages} = docSnapshot.data();
        let languagesMap = languages.filter(lng => typeof lng !== "string").reduce((obj, item) => ({
          ...obj,
          [item.id]: item
        }), {});
        const promises = Object.keys(languagesMap).map(async (lang, i) => {
          const langTranslationSnapshot = await firebase
            .firestore()
            .collection(`translations/${app}/${lang}`)
            .get();
          languagesMap[lang].translation = langTranslationSnapshot.docs.map(doc => ({[doc.id]: doc.data().value}));
        });
        await Promise.all(promises);
        dispatch(setTranslations({[app]: {...languagesMap}, loading: false}));
      });
  } else {
    dispatch(setTranslations({loading: false}));
    dispatch(slice.actions.addDidFetch({key: app}));
  }
};

export const getAppTranslationFB = async app => {
  const translationsCollection = firebase.firestore().collection('translations');
  const doc = await translationsCollection.doc(app).get();
  const isTranslationExistForApp = doc.exists;
  if (isTranslationExistForApp) {
    const {languages} = doc.data();
    const languagesMap = languages.reduce((obj, item) => Object.assign(obj, {[item.id]: item}), {});
    const languagesMapKeys = Object.keys(languagesMap);
    for (let i = 0; i < languagesMapKeys.length; i++) {
      const lang = languagesMapKeys[i];
      const langTranslationSnapshot = await firebase
        .firestore()
        .collection(`translations/${app}/${lang}`)
        .get();
      languagesMap[lang].translation = langTranslationSnapshot.docs.map(doc => ({[doc.id]: doc.data().value}));
    }
    return {...languagesMap};
  }
  return {};
};

export const updateTranslationValue = (appKey: string, langId: string, translationKey: string, value: string): AppThunk => async (
  dispatch,
  getState,
) => {
  const translations = getState().translations[appKey];
  dispatch(
    setTranslations({
      [appKey]: {
        ...translations,
        [langId]: {
          ...translations[langId],
          translation: translations[langId].translation.map(obj => {
            if (translationKey in obj) {
              return {[translationKey]: value};
            }
            return obj;
          }),
        },
      },
    }),
  );
};

export const updateTranslations = (
  appKey: string,
  id: string,
  translationsArray: any[],
  name: string,
  flag: string,
  nativeName: string,
): AppThunk => async (dispatch, getState) => {
  const translations = getState().translations[appKey];
  const oldFlag = translations ? translations[id]?.flag : '';
  dispatch(
    setTranslations({
      [appKey]: {
        ...translations,
        [id]: {
          name,
          id,
          flag: flag || oldFlag,
          nativeName,
          translation: translationsArray,
        },
      },
    }),
  );
};

const updateTranslationsFB = async (
  appKey: string,
  id: string,
  translationsArray: any[],
  name: string,
  flag: string,
  nativeName: string,
) => {
  const appDocRef = firebase
    .firestore()
    .collection('translations')
    .doc(appKey);
  const appDoc = await appDocRef.get();
  const oldFlag = appDoc.data().languages.find(lng => lng.id === id)?.flag;
  await appDocRef.update({
    languages: [
      ...(appDoc.data()?.languages?.filter(lang => lang.id !== id) || []),
      {
        id,
        name,
        flag: flag || oldFlag,
        nativeName,
      },
    ],
  });
  const collectionRef = appDocRef.collection(id);
  const batch = firebase.firestore().batch();
  translationsArray.forEach(translation => {
    const [[k, v]] = Object.entries(translation);
    batch.set(collectionRef.doc(k), {value: v});
  });
  await batch.commit();
};

export const updateTranslationsStoreFB = (
  appKey: string,
  id: string,
  translationsArray: any[],
  name: string,
  flag: string,
  nativeName: string,
): AppThunk => async dispatch => {
  await updateTranslationsFB(appKey, id, translationsArray, name, flag, nativeName);
  dispatch(updateTranslations(appKey, id, translationsArray, name, flag, nativeName));
};

export default slice;
