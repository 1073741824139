import React, { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import BreadcrumbsTree from 'src/components/BreadcrumbsTree';
import { useSelector } from 'src/store';

interface HeaderProps {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    padding: '24px',
  },
}));

const Header: FC<HeaderProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const { title } = useSelector(state => state.app);

  return (
    <Grid container justifyContent="space-between" className={clsx(classes.root, className)} {...rest}>
      <Typography variant="h1" color="textPrimary">
        {title}
      </Typography>
      <Grid item>
        <BreadcrumbsTree />
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
