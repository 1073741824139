export const APP_VERSION = '0.1';

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

export const API = {
  SET_CLAIMS: 'adminSetClaims',
  SEND_EMAIL: 'adminSendEmail',
  GET_USERS: 'adminGetUsers',
  GET_USER_BY_ID: 'adminGetUserById',
  GET_STORE_OWNER: 'adminGetStoreOwner',
  CREATE_USER: 'adminCreateUser',
  IMPERSONATE_USER: 'adminImpersonateUser',
  DISABLE_USER: 'adminDisableUser',
  DELETE_USER: 'adminDeleteUser',
  CREATE_INVOICE: 'adminCreateInvoice',
  SYNC_WITH_SEARCH_SERVER: 'adminSyncWithSearchServer',
  DELETE_FROM_SEARCH_SERVER: 'adminDeleteStoreProductsFromSearch',
  DELETE_INACTIVE_STORE: 'adminDeleteInActiveStore',
};

export const PAGINATION_PAGE_SIZE = [25, 50, 100];
