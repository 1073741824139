import React, { createContext, useEffect, useReducer, FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';
import { useSnackbar } from 'notistack';
import firebaseApiAxiosInstance from 'src/utils/firebaseApiAxios';

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: firebase.User | null;
  claims: {[key: string]: string|boolean}
}

interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth';
  signInWithEmailAndPassword: (email: string, password: string) => Promise<firebase.auth.UserCredential>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: firebase.User | null;
    claims: {[key:string]:string|boolean}
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  claims: {}
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user, claims } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
        claims
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'FirebaseAuth',
  signInWithEmailAndPassword: () => Promise.resolve(null as firebase.auth.UserCredential),
  sendPasswordResetEmail: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar } = useSnackbar();

  const signInWithEmailAndPassword = (email: string, password: string) => firebase.auth().signInWithEmailAndPassword(email, password);
  const sendPasswordResetEmail = (email: string) => firebase.auth().sendPasswordResetEmail(email);

  const logout = (): Promise<void> => firebase.auth().signOut();

  const dispatchAuthUser = async (user: firebase.User) => {
    const token = await user.getIdToken();
    firebaseApiAxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    const { claims } = await user.getIdTokenResult();
    dispatch({
      type: 'AUTH_STATE_CHANGED',
      payload: {
        isAuthenticated: true,
        user,
        claims
      },
    });
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      try {
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          if (!idTokenResult.claims.admin && !idTokenResult.claims.paymob) {
            firebase.auth().signOut();
            enqueueSnackbar("You're not authorized to acccess this application", { variant: 'error' });
          } else {
            dispatchAuthUser(user);
          }
        } else {
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: false,
              user: null,
              claims: {}
            },
          });
        }
      } catch (error) {
        enqueueSnackbar('Failed to login', { variant: 'error' });
      }
    });

    return unsubscribe;
  }, [dispatch, enqueueSnackbar]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        signInWithEmailAndPassword,
        sendPasswordResetEmail,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
