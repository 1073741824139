import { Breadcrumbs, Button, IconButton } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { ChevronRight, Home } from 'react-feather';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const BreadcrumbsTree: FC = () => {
  const [paths, setPaths] = useState(['/']);
  const location = useLocation();

  useEffect(() => {
    const tree = [...new Set(location.pathname.split('/'))];
    if (location.state) {
      const { title } = location.state as any;
      tree[tree.length - 1] = title;
    }

    setPaths(['/', ...tree.filter(path => path)]);
  }, [location]);

  const pathLink = (pathIndex: number) => paths.slice(1, pathIndex).join('/');

  return (
    <Breadcrumbs separator={<ChevronRight />}>
      {paths.map((path, i) => {
        if (path === 'view' || path === 'edit') {
          return null;
        }
        if (i === 0) {
          return (
            <IconButton key={path} color="primary" component={Link} to="/">
              <Home size="20px" />
            </IconButton>
          );
        }
        if (i === paths.length - 1) {
          return (
            <Button key={path} disabled>
              {paths[i - 2] && paths[i - 1]} {path}
            </Button>
          );
        }
        return (
          <Button key={path} color="primary" component={Link} to={`/${pathLink(i + 1)}`}>
            {path}
          </Button>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadcrumbsTree;
