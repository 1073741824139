import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import PaymobGuard from 'src/components/PaymobGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/forgot-password',
    component: lazy(() => import('src/views/auth/ForgotPasswordView')),
  },
  {
    exact: true,
    path: '/auth',
    component: lazy(() => import('src/views/auth/EmailHandlerView')),
  },
  {
    exact: true,
    guard: PaymobGuard,
    path: '/paymob',
    component: lazy(() => import('src/views/paymob/PaymobView')),
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/dashboard/DashboardView')),
      },
      {
        exact: true,
        path: '/users',
        component: lazy(() => import('src/views/users/UsersListView')),
      },
      {
        exact: true,
        path: '/users/create',
        component: lazy(() => import('src/views/users/UserCreateView')),
      },
      {
        exact: true,
        path: '/users/view/:id',
        component: lazy(() => import('src/views/users/UserDetailsView')),
      },
      {
        exact: true,
        path: '/stores',
        component: lazy(() => import('src/views/stores/StoreListView')),
      },
      {
        exact: true,
        path: '/stores/view/:id',
        component: lazy(() => import('src/views/stores/StoreDetailsView')),
      },
      {
        exact: true,
        path: '/translation',
        component: lazy(() => import('src/views/translation')),
      },
      {
        exact: true,
        path: '/issues',
        component: lazy(() => import('src/views/supportIssues')),
      },
      {
        exact: true,
        path: '/applications',
        component: lazy(() => import('src/views/applications/ApplicationsLisView')),
      },
      {
        exact: true,
        path: '/applications/view/:id',
        component: lazy(() => import('src/views/applications/ApplicationDetailsView')),
      },
      {
        exact: true,
        path: '/invoices',
        component: lazy(() => import('src/views/invoices/InvoicesListView')),
      },
      {
        exact: true,
        path: '/invoices/create',
        component: lazy(() => import('src/views/invoices/InvoiceCreateView')),
      },
      {
        exact: true,
        path: '/maintenance',
        component: lazy(() => import('src/views/maintenance/index')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
