import { combineReducers } from '@reduxjs/toolkit';
import { reducer as appReducer } from 'src/slices/app';
import { reducer as translationReducer } from 'src/slices/translations';
import { reducer as invoicesReducer } from 'src/slices/invoices';
import { reducer as storesReducer } from 'src/slices/stores';

const rootReducer = combineReducers({
  app: appReducer,
  translations: translationReducer,
  invoicesReducer,
  storesReducer
});

export default rootReducer;
