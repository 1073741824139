import store from 'src/store';
import { firestore } from 'firebase/app';
import { Invoice } from 'src/types/Invoice';
import { setInvoices } from 'src/slices/invoices';
import firebaseApiAxiosInstance from 'src/utils/firebaseApiAxios';
import { API } from 'src/constants';

const INVOICES_COLLECTION_NAME = 'invoices';

function getInvoicesCollectionRef(): firestore.Query<firestore.DocumentData> {
  return firestore().collectionGroup(INVOICES_COLLECTION_NAME);
}

function convertFromFirestore(snapshot: firestore.QueryDocumentSnapshot): Invoice {
  const storeId = snapshot.ref.parent.parent.id;
  return { id: snapshot.id, ...snapshot.data(), storeId } as Invoice;
}

function convertToFirestore(invoice: Invoice): firestore.DocumentData {
  delete invoice.id;
  return invoice;
}

export const invoiceConverter = {
  fromFirestore: convertFromFirestore,
  toFirestore: convertToFirestore,
};

export function subscribeToInvoicesFB(): void {
  const collectionRef = getInvoicesCollectionRef();
  collectionRef.withConverter(invoiceConverter).onSnapshot(snapshot => {
    const invoices = snapshot.docs.map(doc => doc.data());
    store.dispatch(setInvoices(invoices));
  });
}

export async function createInvoiceFB(invoice: Invoice, storeId: string): Promise<void> {
  await firebaseApiAxiosInstance.post(API.CREATE_INVOICE, { invoice, storeId });
}
