import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createInvoiceFB, subscribeToInvoicesFB } from 'src/lib/invoices';
import { AppThunk } from 'src/store';
import { Invoice } from 'src/types/Invoice';

interface InvoicesState {
  invoices: Invoice[];
  isSubscriped: boolean;
}

const initialState: InvoicesState = {
  invoices: [],
  isSubscriped: false,
};

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoices(state: InvoicesState, action: PayloadAction<Invoice[]>): void {
      const invoices = action.payload;
      state.invoices = invoices;
      state.isSubscriped = true;
    },
  },
});

export const { reducer } = slice;

export const { setInvoices } = slice.actions;

export const subscribeToInvoices = (): AppThunk => async (dispatch, getState): Promise<void> => {
  const { isSubscriped } = getState().invoicesReducer;
  if (isSubscriped) return;
  subscribeToInvoicesFB();
};

export const createInvoice = (invoice: Invoice, storeId: string): AppThunk => async (): Promise<void> => {
  await createInvoiceFB(invoice, storeId);
};

export default slice;
