import React, { FC } from 'react';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = props => {
  const appSettings = useSettings();
  const isDarkTheme = appSettings.settings.theme === THEMES.DARK;
  const logoUrl = `/static/${isDarkTheme ? 'logo-white' : 'logo'}.svg`;
  return <img alt="Logo" src={logoUrl} {...props} />;
};

export default Logo;
