import { API } from 'src/constants';
import { firestore } from 'firebase/app';
import Store from 'src/types/store';
import firebaseApiAxiosInstance from 'src/utils/firebaseApiAxios';
import store from 'src/store';
import { setStores } from 'src/slices/stores';
import { getBusinessInfoByStoreId } from './BusinessInfo';
import { getStoreTypeById } from './StoreTypes';
import { getStoreOwnerUser, getAllAuthUsers } from './Users';

export const STORES_COLLECTION_NAME = 'stores';

const getStoresCollection = (): firestore.CollectionReference => firestore().collection(STORES_COLLECTION_NAME);

const convertFromDocument = (snapshot: firestore.QueryDocumentSnapshot): Store => {
  const data = snapshot.data();
  return { ...data, id: snapshot.id, followUp: !!data.followUp } as Store;
};

const convertToFirestore = (store: Store): firestore.DocumentData => store;

export const storesConverter = {
  fromFirestore: convertFromDocument,
  toFirestore: convertToFirestore,
};

export const subscribeToStoresFB = (): void => {
  const collection = getStoresCollection();
  collection.withConverter(storesConverter).onSnapshot(snapshot => {
    const stores = snapshot.docs.map(doc => doc.data());
    store.dispatch(setStores(stores));
  });
};

export const subscribeToAllStores = (onNext: (stores: Store[]) => void): (() => void) => {
  const collection = getStoresCollection();
  return collection.withConverter(storesConverter).onSnapshot(async snapshot => {
    const authUsers = await getAllAuthUsers();
    const storesPromises = snapshot.docs.map(async doc => {
      const owner = authUsers.find(user => user.customClaims?.store === doc.id);
      return { ...doc.data(), owner };
    });
    const stores = await Promise.all(storesPromises);
    onNext(stores);
  });
};

export const subscribeToStoreById = (storeId: string, onNext: (store: Store) => void): (() => void) => {
  const collection = getStoresCollection();
  return collection
    .withConverter(storesConverter)
    .doc(storeId)
    .onSnapshot(async snapshot => {
      const owner = await getStoreOwnerUser(storeId);
      const businessInfo = await getBusinessInfoByStoreId(storeId);
      const storeType = snapshot.data().storeType && (await getStoreTypeById(snapshot.data().storeType)).name;
      onNext({ ...snapshot.data(), owner, businessInfo, storeType });
    });
};

export const getStoreById = async (storeId: string): Promise<Store> => {
  const collection = getStoresCollection();
  return (
    await collection
      .withConverter(storesConverter)
      .doc(storeId)
      .get()
  ).data();
};

export const activateStore = async (active: boolean, storeId: string, activatedBefore: boolean): Promise<void> => {
  const collection = getStoresCollection();
  const updatedStore: { active: boolean; activationTime?: number } = { active };
  if (active && !activatedBefore) {
    updatedStore.activationTime = Date.now();
  }
  await collection.doc(storeId).update(updatedStore);
};

export const impersonateStoreUser = async (uid: string): Promise<string> =>
  (await firebaseApiAxiosInstance.get(`${API.IMPERSONATE_USER}/${uid}`)).data.token;

export const followUpStore = async (id: string, followUp: boolean): Promise<void> => {
  const collection = getStoresCollection();
  await collection.doc(id).update({ followUp });
};

export const syncWithSearchServer = async (storeId: string) => firebaseApiAxiosInstance.get(`${API.SYNC_WITH_SEARCH_SERVER}/${storeId}`);

export const deleteStoreProductsFromSearch = async (storeId: string) =>
  firebaseApiAxiosInstance.delete(`${API.DELETE_FROM_SEARCH_SERVER}/${storeId}`);

export const subscribeToInactiveStores = (onNext: (stores) => void): (() => void) => {
  const collection = firestore()
    .collection(STORES_COLLECTION_NAME)
    .where('active', '==', false);
  return collection.onSnapshot(async snapshot => {
    const storesPromises = snapshot.docs.map(async doc => {
      return { ...doc.data(), id: doc.id };
    });
    const stores = await Promise.all(storesPromises);
    onNext(stores);
  });
};
