import { API } from 'src/constants';
import { EmailTypes, ROLES, User } from 'src/types/user';
import firebase from 'src/lib/firebase';
import firebaseApiAxiosInstance from 'src/utils/firebaseApiAxios';

export const getUserRoleFromClaims = (user: User): ROLES => {
  if (user.customClaims?.admin) {
    return ROLES.ADMIN;
  }
  if (user.customClaims?.paymob) {
    return ROLES.PAYMOB;
  }
  if (user.customClaims?.store) {
    return ROLES.STORE;
  }
  return ROLES.USER;
};

export const getAllAuthUsers = async (): Promise<User[]> => {
  const users = (await firebaseApiAxiosInstance.get(API.GET_USERS)).data.users as User[];
  return users.map(user => ({
    ...user,
    role: getUserRoleFromClaims(user),
  }));
};

export const getAuthUserById = async (id: string): Promise<User> => {
  const user = (await firebaseApiAxiosInstance.get(`${API.GET_USER_BY_ID}/${id}`)).data as User;
  return { ...user, role: getUserRoleFromClaims(user) };
};

export const getUserDocById = async (id: string): Promise<User> => {
  const userDoc = await firebase.firestore().collection('users').doc(id)
    .get();
  return { ...userDoc.data(), uid: userDoc.id } as User;
};

export const getStoreOwnerUser = async (storeId: string): Promise<User> => {
  const userDoc = (await firebase.firestore().collection('users').where('storeId', '==', storeId).get()).docs[0];
  const authUser = await getAuthUserById(userDoc.id);
  return { ...userDoc.data(), ...authUser, uid: userDoc.id } as User;
};

export const changeUserRole = async (role: string, uid: string): Promise<void> => {
  await firebaseApiAxiosInstance.post(API.SET_CLAIMS, { uid, claim: role });
};

export const sendActionEmailToUser = async (email: string, type: EmailTypes): Promise<void> => {
  await firebaseApiAxiosInstance.post(API.SEND_EMAIL, { email, type });
};

export const disableUser = async (uid: string, disabled: boolean, reason: string): Promise<void> => {
  await firebaseApiAxiosInstance.post(API.DISABLE_USER, { uid, disabled, reason });
};

export const deleteUser = async (uid: string): Promise<void> => {
  await firebaseApiAxiosInstance.post(API.DELETE_USER, { uid });
};
