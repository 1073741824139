/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from 'src/types/user';

interface AppState {
  title: string;
  loading: boolean;
  user: User;
}

const initialState: AppState = {
  title: '',
  loading: true,
  user: null,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading(state: AppState, action: PayloadAction<boolean>) {
      return { ...state, loading: action.payload };
    },
    setAppTitle(state: AppState, action: PayloadAction<string>) {
      return { ...state, title: action.payload };
    },
    setUser(state: AppState, action: PayloadAction<User>) {
      return { ...state, user: action.payload };
    },
  },
});

export const { reducer } = slice;

export const { setAppTitle, setAppLoading, setUser } = slice.actions;
export const selectApp = (state: AppState) => state;

export default slice;
