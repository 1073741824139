import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subscribeToStoresFB } from 'src/lib/Stores';
import { AppThunk } from 'src/store';
import Store from 'src/types/store';

interface StoresState {
  stores: Store[];
  isSubscriped: boolean;
}

const initialState: StoresState = {
  stores: [],
  isSubscriped: false,
};

const slice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setStores(state: StoresState, action: PayloadAction<Store[]>): void {
      const stores = action.payload;
      state.stores = stores;
      state.isSubscriped = true;
    },
  },
});

export const { reducer } = slice;

export const { setStores } = slice.actions;

export const subscribeToStores = (): AppThunk => (dispatch, getState): Promise<void> => {
  const { isSubscriped } = getState().storesReducer;
  if (isSubscriped) return;
  subscribeToStoresFB();
};

export default slice;
