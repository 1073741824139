import firebase from 'src/lib/firebase';
import { StoreType } from 'src/types/store';

const getStoreTypesCollection = (): firebase.firestore.CollectionReference => firebase.firestore().collection('store-types');

const convertFromDocument = (snapshot: firebase.firestore.QueryDocumentSnapshot): StoreType => {
  const data = snapshot.data();
  return { ...data, id: snapshot.id } as StoreType;
};

const convertToFirestore = (storeType: StoreType): firebase.firestore.DocumentData => storeType;

export const storeTypeConverter = {
  fromFirestore: convertFromDocument,
  toFirestore: convertToFirestore
};

export const getStoreTypeById = async (id: string): Promise<StoreType> => {
  const collection = getStoreTypesCollection();
  return (await collection.withConverter(storeTypeConverter).doc(id).get()).data();
};
